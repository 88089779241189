/* eslint-disable import/first */
// import/first : 0
// eslint-disable-next-line
import config from '../../config';
import Log from '../../lib/Log';
import React, { Component } from 'react';
import API from '../../lib/api';
import './Events.css';
//import Clock from '../Clock/Clock';
import Clock from 'react-live-clock';
import Design from '../Design/Design';
import utils from '../../lib/utils';
//import axios from 'axios';

/**
* This is the app core class to get and manage the events
* 
* @class: Events
* 
*/
class Events extends Component {
    /**
     * constructor
     * @param {object} props
     */
    constructor(props) {
        super(props);
        /**
         * @type {object}
         * @property {number} error error id
         * @property {boolean} loading state of loading
         * @property {object} device default parameter
         * @property {object} events the filtered event for current view
         * @property {object} eventsFull the complete eventes from api
         * @property {string} art url extracted string of display app type (doorsign/overview)
         * @property {number} design number of design
         * @property {object} hardware mac address
         * @property {object} orientation the display orientation of the device
         * @property {object} time current locale time
         * 
         */
        this.state = {
            error: null,
            loading: true,
            device: {
                name: '',
                emaIcons: [],
                bgUrl: '',
                logoUrl: '',
                template: {
                    design: {
                        title: '',
                        mode: '',
                        number: '1'
                    },
                    textColor: null,
                    bgColor: null,
                    entryTextColor: null,
                    entryBgColor: null,
                    entryIconColor: null,
                    mode: '',
                    logo: {
                        srcUrl: '',
                        description: ''
                    },
                    bgImg: {
                        srcUrl: '',
                        description: ''
                    },
                    buildingId: '',
                },
                events: {}
            },
            events: [],
            eventsFull: [],
            art: '',
            design: '0',
            hardware: '',
            orientation: '',
            time: new Date().toLocaleString()
        };
    }

    /**
    * If the component will mount then initate the API data
    *
    * @function: componentWillMount
    * @description: If the component will mount then initate the API data
    * 
    */
    UNSAFE_componentWillMount() {
        this.getDataViaApi();
        /*
        if(!this.state.hardware) {
            (async () => {
                try {
                    this.setState({hardware: await this.getHWData()});
                } catch(e) {
                    //...handle the error...
                }
            })();
        }
        */
    }

    /**
    * 
    * @function: getHWData
    * @description: Get the device-parameter like mac via a js fileload
    * 
    * @return {type} Description.
    * 
    */
    async getHWData() {
        //const res = await axios(config.HW_URL);
        ////Log.error('HW RESPONSE', res);
        //return await res.json();
    }

    /**
    * 
    * @function: getScreenOrientation
    * @description: Get the device-parameter like mac via a js fileload
    * 
    * @param {type}   var           Description.
    * @return {type} Description.
    * 
    */
    getScreenOrientation() {
        var orientation = {};
        var orientationType = window.screen.msOrientation || window.screen.mozOrientation || (window.screen.orientation || {}).type;
        orientation.angle = window.screen.msOrientation || window.screen.mozOrientation || (window.screen.orientation || {}).angle;
        orientation.type = orientationType.split('-')[0];
        Log.error('ORIENTATION', window.screen.orientation);
        /*
        } else if(window.innerHeight > window.innerWidth) {
            orientation.type = 'landscape';
        } else if(window.innerHeight < window.innerWidth) {
            orientation.type = 'portrait';
        }
        */
        /*
        if (window.matchMedia("(orientation: portrait)").matches) {
            
        }
        if (window.matchMedia("(orientation: landscape)").matches) {
            
        }
        */
        return orientation;
    }

    /**
    * 
    * @function: getDataViaApi
    * @description: Get the device and event data via the graphql interface
    * 
    * @return {type} Description.
    * 
    * 
            emaIcons {
              roomId
              order
              content {
                id
              }
              contentId
            }
    */
    getDataViaApi() {
        var handle = this.props.match.params.dId;
        var art = this.props.match.path.split('/')[1];
        var screenOrientation = this.getScreenOrientation();
        var querystring = `
        query {
            device(id:"${handle}") {
                name
                room {
                    name
                }
                emaIcons {
                    roomId
                    order
                    content {
                        id
                        srcUrl
                        name
                    }
                }
                template {
                    design {
                        title
                        mode
                        number
                    }
                    textColor
                    bgColor
                    entryTextColor
                    entryBgColor
                    entryIconColor
                    mode
                    logo {
                        srcUrl
                        description
                    }
                    bgImg {
                        srcUrl
                        description
                    }
                    buildingId
                }
                events(onlyRunning: false) {
                    id
                    roomName
                    description
                    logo {
                        srcUrl
                    }
                    roomIds
                    roomId
                    title
                    start
                    end
                    showStart
                    showEnd
                }
            }
        }
        `;

        //this.serverRequest = API.post(``, {
        API.post(``, {
            query: querystring
            },{
                headers: {
                    'Content-Type': 'application/json'
            }
        }).then((deviceResponse) => {
            //Log.info('deviceResponse', deviceResponse);
            //TODO: handle real neckbreaker errors
            if(deviceResponse.status === 200){// && !deviceResponse.data.error.length) {
                const resp = deviceResponse.data;
                Log.info('componentDidMount APIcall DATA', resp);
                //if(resp.data.device.events.length && typeof resp.data.device.events[0] != 'undefined') {
                if(Array.isArray(resp.data.device.events) && typeof resp.data.device.events[0] != 'undefined') {
                    Log.trace('componentDidMount API.get EVENTS', resp.data.device.events);

                    if(resp.data.device.checkInterval > 0) {
                        this.checkInterval = resp.data.device.checkInterval * 1000;
                    } else {
                        this.checkInterval = config.CHECK_INTERVAL * 1000;
                    }
                    if(this.checkeventsIntervalID) {
                        clearInterval(this.checkeventsIntervalID);
                    }
                    this.checkeventsIntervalID = setInterval(
                        () => {
                            //Log.trace('ART', this.state.art);
                            if(this.state.art === 'overview') {
                                //if(!utils.isEquivalent(this.state.events, this.sortEventsOV(this.state.eventsFull))) {
                                    //Log.trace('ART', this.state.art);
                                    this.setState( { events: this.sortEventsOV(this.state.eventsFull) } );
                                //}
                            } else if(this.state.art === 'doorsign') {
                                this.setState( { events: this.sortEventsDS(this.state.eventsFull) } );
                            } else {
                                
                            }
                        },
                        this.checkInterval
                    );

                    if(this.geteventsIntervalID) clearInterval(this.geteventsIntervalID);
                    //Log.error('ART=mode', resp.data.device.template.mode);
                    if(art === 'overview') {
                        this.setState( { loading: false, error: '', device: resp.data.device, events: this.sortEventsOV(resp.data.device.events), eventsFull: resp.data.device.events, art: art, orientation: screenOrientation, design: resp.data.device.template.design.number.toString() } );
                        //Log.info('SETSTATE OV', this.state);
                    } else if(art === 'doorsign') {
                        this.setState( { loading: false, error: '', device: resp.data.device, events: this.sortEventsDS(resp.data.device.events), eventsFull: resp.data.device.events, art: art, orientation: screenOrientation, design: resp.data.device.template.design.number.toString() } );
                        //Log.info('SETSTATE DS', this.state);
                    } else {
                        //Log.error('ERROR NO ART', art);
                    }
                } else {
                    Log.trace('componentDidMount API.get NOEVENTS', resp.data.device);
                    if(art === 'overview' || art === 'doorsign') {
                        this.setState( { loading: false, device: resp.data.device, art: art } );
                        //Log.info('SETSTATE OV-DS', this.state);
                    } else {
                        //Log.error('ERROR NO ART', art);
                    }

                    //TODO: wenn Leer nach eventuell x Sekunden neu starten und noch einmal probieren
                    /*
                    this.getInterval = (resp.data.device.getInterval > 0) ? resp.data.device.getInterval * 1000 : config.GET_INTERVAL * 1000;
                    if(this.geteventsIntervalID) clearInterval(this.geteventsIntervalID);
                    this.geteventsIntervalID = setInterval(
                        () => this.getDataViaApi(),
                        this.getInterval
                    );
                    */

                }
            } else {
                //TODO: wenn Fehler nach eventuell x Sekunden neu starten und noch einmal probieren
                Log.error('componentDidMount APIcall STATUS-ERROR', deviceResponse.status);
                deviceResponse.data.error.forEach(msg => Log.error('componentDidMount APIcall ERROR', msg.message) );
            }
        }).catch(error => {
            this.getInterval = config.GET_INTERVAL * 1000;
            if(this.geteventsIntervalID) clearInterval(this.geteventsIntervalID);
            this.geteventsIntervalID = setInterval(
                () => this.getDataViaApi(),
                this.getInterval
            );
            this.setState( { error: error.code } );
            if(error.code === 'ECONNABORTED') {
                Log.error('Event API timeout', error.code);
                return 'timeout';
            } else {
                Log.error('Event API response', error.code);
                throw error;
            }
        });
    }

    /**
    * 
    * @function: componentDidMount
    * @description: Do things if the component is mount
    * 
    * @param {type}   var           Description.
    * @return {type} Description.
    * 
    */
    componentDidMount() {
        /*
        this.getInterval = (this.state.device.getInterval > 0) ? this.state.device.getInterval * 1000 : config.GET_INTERVAL * 1000;
        if(this.geteventsIntervalID) clearInterval(this.geteventsIntervalID);
        this.geteventsIntervalID = setInterval(
            () => this.getDataViaApi(),
            this.getInterval
        );
        */
    }

    /**
    * 
    * @function: componentWillUnmount
    * @description: If the component will mount then remove the timers
    * 
    * @param {type}   var           Description.
    * @return {type} Description.
    * 
    */
    componentWillUnmount() {
        if(this.geteventsIntervalID) clearInterval(this.geteventsIntervalID);
        if(this.checkeventsIntervalID) clearInterval(this.checkeventsIntervalID);
        //this.serverRequest.abort();
    }

    /**
    * 
    * @function: mapData
    * @description: Map the eventdata to a usable needed structure
    * 
    * @param {type}   event           object with a list of event data.
    * @return {type} Description.
    * 
    */
    mapData(event) {
        event.header = '';
        event.logoUrl = event.logo.srcUrl || '';
        event.preTime = event.preTime || 0;
        event.postTime = event.postTime || 0;
        event.starttime = +(utils.getDate(utils.convertISOString(event.start)));
        event.endtime = +(utils.getDate(utils.convertISOString(event.end)));
        event.duration = event.endtime - event.starttime;
        event.preShow = +(utils.getDate(utils.convertISOString(event.showStart))); //(+event.starttime) - (+event.preTime * 60 * 1000);
        event.postShow = +(utils.getDate(utils.convertISOString(event.showEnd))); //(+event.endtime) + (+event.postTime * 60 * 1000);
        event.startTimeStr = utils.getDatetime('time', event.starttime);
        event.endTimeStr = utils.getDatetime('time', event.endtime);
        event.startDateStr = utils.getDatetime('date', event.starttime);
        event.endDateStr = utils.getDatetime('date', event.endtime);
        //Log.trace('SORT EVENT', event);
        return event;
    }

    /**
    * 
    * @function: sortEventsOV
    * @description: 
    * 
    * @param {type}   event           object with a list of event data.
    * @return {type} Description.
    * 
    */
    sortEventsOV(events) {
        if(Array.isArray(events) && typeof events[0] !== 'undefined' && events[0].start !== '') {
            return events.map(event => {
                return this.mapData(event);
            })
            .filter(elem => { return elem.postShow > utils.getDate(); })
            //.filter(elem => { return utils.sameDate(utils.getDate(elem.starttime), utils.getDate()) || utils.sameDate(utils.getDate(elem.endtime), utils.getDate()); })
            //.filter(elem => { return utils.betweenDates(utils.getDate(elem.starttime), utils.getDate(elem.endtime), utils.getDate()); })
            .filter(elem => { return utils.betweenDays(utils.getDate(elem.preShow), utils.getDate(elem.postShow), utils.getDate()); })
            .sort((a, b) => (a.starttime < b.starttime) ? -1 : ((a.starttime > b.starttime) ? 1 : 0));
        } else {
            return {};
        }
    }

    /**
    * 
    * @function: sortEventsDS
    * @description: 
    * 
    * @param {type}   event           object with a list of event data.
    * @return {type} Description.
    * 
    */
    sortEventsDS(events) {
        if(Array.isArray(events) && typeof events[0] !== 'undefined' && events[0].start !== '') {
            //Log.error('EVENTS0', events);
            var mappedEvents = events.map(event => {
                return this.mapData(event);
            })
            .filter(elem => { return elem.preShow <= utils.getDate() && elem.postShow >= utils.getDate(); });
            //Log.error('EVENTS1', mappedEvents[0]);
            return new Array(mappedEvents[0]) || {};
        } else {
            return {};
        }
    }

    /**
     * render
     * @return {ReactElement} markup
     */
    render() {
        Log.info('EVENTS render this', this);

        var styles = {
            mainbg: {
                backgroundImage: `url(${ this.state.device.bgUrl })`,
            }
        };

        return (
                <div className="App" id="App" style={styles.mainbg}>
                    {this.state.loading ? <div id="loading">Loading...{this.state.error}</div> : <Design design={this.state.design} art={this.state.art} orientation={this.state.orientation} events={this.state.events} device={this.state.device} />}
                    {config.DEBUG_CLOCK === '1' ? <Clock id="debugclock" format={'DD.MM.YYYY HH:mm:ss'} ticking={true} timezone={'Europe/Berlin'} date={config.DEBUG_TIME} /> : ''}
                    {/*this.state.hardware ? <em>Loading hardware data</em> : this.state.hardware*/}
                    <div id="debugwarning">{config.showDebugMessage(this.state.device.template.design.mode, this.state.device.template.design.number)}</div>
                </div>
        );
    }
}

export default Events;
