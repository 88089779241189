import React, { Component } from 'react';
import Log from '../../lib/Log';

import Design0 from './Design0.jsx';

import Design1ov from './Overview/Design1.jsx';
import Design2ov from './Overview/Design2.jsx';
import Design3ov from './Overview/Design3.jsx';

import Design1ds from './Doorsign/Design1.jsx';
import Design2ds from './Doorsign/Design2.jsx';
import Design3ds from './Doorsign/Design3.jsx';

/**
* Here is the selection made which design should be displayed
* 
* @class: Design
* 
*/
class Design extends Component {
    /**
     * render
     * @return {ReactElement} markup
     */
    render() {
        //Log.error('PROPS', this.props);
        var designComp = '';
        if(this.props.art === 'overview' && this.props.device.template.design.mode && Array.isArray(this.props.events) && typeof this.props.events[0] != 'undefined') {
        // OVERVIEW this.props.device.template.design.mode === 2
            switch(this.props.design) {
                case '1':
                    this.props.device.viewmodename = 'OV1';
                    designComp = <Design1ov events={this.props.events} device={this.props.device} orientation={this.props.orientation} />;
                    Log.info('DESIGN 1 OV');
                    break;
                case '2':
                    this.props.device.viewmodename = 'OV2';
                    designComp = <Design2ov events={this.props.events} device={this.props.device} orientation={this.props.orientation} />;
                    Log.info('DESIGN 2 OV');
                    break;
                case '3':
                    this.props.device.viewmodename = 'OV3';
                    designComp = <Design3ov events={this.props.events} device={this.props.device} orientation={this.props.orientation} />;
                    Log.info('DESIGN 3 OV');
                    break;
                default:
                    //designComp = <Design1ov events={this.props.events} device={this.props.device} />;
                    designComp = <Design0 device={this.props.device} orientation={this.props.orientation} />;
                    Log.info('DESIGN 0 OV DEFAULT');
            }
        } else if(this.props.art === 'doorsign' && this.props.device.template.design.mode && Array.isArray(this.props.events) && typeof this.props.events[0] != 'undefined') {
        // DOORSIGN this.props.device.template.design.mode === 1
            switch(this.props.design) {
                case '1':
                    this.props.device.viewmodename = 'DS1';
                    designComp = <Design1ds events={this.props.events} device={this.props.device} orientation={this.props.orientation} />;
                    Log.info('DESIGN 1 DS');
                    break;
                case '2':
                    this.props.device.viewmodename = 'DS2';
                    designComp = <Design2ds events={this.props.events} device={this.props.device} orientation={this.props.orientation} />;
                    Log.info('DESIGN 2 DS');
                    break;
                case '3':
                    this.props.device.viewmodename = 'DS3';
                    designComp = <Design3ds events={this.props.events} device={this.props.device} orientation={this.props.orientation} />;
                    Log.info('DESIGN 3 DS');
                    break;
                default:
                    //designComp = <Design1ds events={this.props.events} device={this.props.device} />;
                    designComp = <Design0 device={this.props.device} orientation={this.props.orientation} />;
                    Log.info('DESIGN 0 DS DEFAULT');
            }
        } else {
            this.props.device.viewmodename = 'ELSE0';
            designComp = <Design0 device={this.props.device} orientation={this.props.orientation} />;
            Log.info('DESIGN 0 - Error');
        }

        return <div>{designComp}</div>;
    }
}

export default Design;
