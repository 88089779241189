import React, { Component } from 'react';
//import Log from '../../lib/Log';
import Img from 'react-image';
import './Design0.scss';
import Clock from 'react-live-clock';
//import logo from '../../images/triax_log.svg';
//import roomImage from '../../images/room.svg';
import config from '../../config';

/**
 * Take the imageurl and give the jsx imageobject back.
 * 
 * @function: templateImage
 *
 * @param {string} logoUrl Url of the logo as a string
 * @returns {object} Imageobject
 * 
 */
var templateImage = (logoUrl) => {
    return <Img className="template-logo" src={logoUrl} />;
};

/**
 * The design0 is the idle screen. In a normal ds-player mode this page will never be shown.
 * 
 * @class: Design0
 * 
 */
class Design0 extends Component {
    /**
     * constructor
     * @param {object} props
     */
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    /**
    * Get the device information and display it.
    *
    * @function: deviceInfo
    * @returns {object} element
    * 
    */
    deviceInfo() {
        var dev = this.props.device;
        //Log.error('DEV', dev);
        var styleColor = '#F2F2F2';
        if(dev.template.textColor) {
            styleColor = dev.template.textColor;
        }
        var styles = {
            mainbg: {
                fill: styleColor,
            },
            styleHead: {
                /*backgroundColor: dev.template.bgColor,*/
                color: dev.template.textColor
            }
        };

        return (
            <div className="trx header">
                <div className="trx-1-3 left">
                    {templateImage(dev.template.logo.srcUrl)}
                </div>
                <div className="trx-2-3 right" style={styles.styleHead}>
                    {<Clock id="clock" format={'DD.MM.YYYY | HH:mm'} ticking={true} timezone={'Europe/Berlin'} date={config.DEBUG_TIME} />}
                    <div className="roomSetup">
                      <svg xmlns="http://www.w3.org/2000/svg" style={styles.mainbg} className="roomPic" width="14" height="20" viewBox="0 0 14 20"><path d="M7,0C3.1,0,0,3.1,0,7c0,5.2,7,13,7,13s7-7.8,7-13C14,3.1,10.9,0,7,0z M7,9.5C5.6,9.5,4.5,8.4,4.5,7S5.6,4.5,7,4.5
S9.5,5.6,9.5,7S8.4,9.5,7,9.5z"/></svg>{/*<Img style={styles.mainbg} className="roomPic" src={roomImage} />*/}
                      <div className="roomName">
                        {dev.room.name}
                      </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        var stylesMain = {
            backgroundColor: this.props.device.template.bgColor,
            color: this.props.device.template.textColor
        };

        return (
            <div id='d0' className="container" style={stylesMain}>
                {this.deviceInfo()}
            </div>
        );
    }
}

export default Design0;
