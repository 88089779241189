/* global Date:writable */
import React, { Component } from 'react';
//import logo from './logo.svg';
//import dotenv from 'dotenv';
//dotenv.config();
import NotFound from './pages/NotFound';
import NoAccess from './pages/NoAccess';
import Events from './components/Events/Events';
//import Overview from './components/Overview/Overview';
//import Doorsign from './components/Doorsign/Doorsign';
import config from './config';
import Log from './lib/Log';
import { HashRouter, Switch, Route } from 'react-router-dom';

//import myMessages from './myMessages';
//import * as domainMessages from 'i18n';
/*
const messages = {
    'en': {
        //...englishMessages,
        ...domainMessages.en,
        myMessages
    },
    'fr': {
        //...frenchMessages,
        ...domainMessages.fr,
        myMessages
    },
    'de': {
        //...germanMessages,
        ...domainMessages.de,
        myMessages
    },
    'da': {
        //...danishMessages,
        ...domainMessages.da,
        myMessages
    },
    'es': {
        //...spanishMessages,
        ...domainMessages.es,
        myMessages
    },
};
*/

if(config.DEBUG_TIME) {
    //var oldTime = new Date().now;
    var curTime = Date.parse(config.DEBUG_TIME);
    //Log.error('oldTIME', oldTime);
    //Log.error('curTIME', curTime);

    Date = class extends Date {
        constructor(...options) {
            if(options.length) {
                super(...options);
            } else {
                super(curTime);
            }
        }
    };
    Log.info("DEBUG DATE", new Date());
}

class App extends Component {
    render() {
        return (
            <div>
                <HashRouter>
                    <Switch>
                        <Route exact path="/" component={NoAccess} />
                        <Route path="/doorsign/:dId" component={Events} />
                        <Route path="/overview/:dId" component={Events} />
                        <Route path="*" component={NotFound} />
                    </Switch>
                </HashRouter>
            </div>
        );
    }
}

export default App;
