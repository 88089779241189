/** 
 * @description Configuration parameters for DEVELOPMENT state
 * @type {object}
*/
const dev = {
    //Debugmode on/off with [true/false]
    DEBUG: true,
    //Debuglevel only show messages if DEBUG is set true
    // 0 = show only error messages
    // 1 = show additionally warn messages
    // 2 = show additionally info messages
    // 3 = show additionally trace messages
    DEBUG_LEVEL: '3',
    //Debugtext shows on screen
    DEBUG_WARNING: 'DEBUG MODE',
    //Debugclock shows on screen
    // 0 = off
    // 1 = digital clock
    DEBUG_CLOCK: '1',
    //Static clock used
    // 0 = off
    // 1 = on
    DEBUG_STATIC_CLOCK: '0',
    //Set time string e.g. ""
    DEBUG_STATIC_CLOCK_TIME: '',
    //Load debug dummy data instead of fetch api data [true/false]
    DEBUG_DUMMYDATA: true,
    //Interval to check the eventtime to remove obsolet events [seconds]
    CHECK_INTERVAL: '30',
    //Interval to fetch data from api [seconds]
    GET_INTERVAL: '60',
    //Interval to slide the events entries [ms]
    SLIDER_SPEED: '10000',
    //Api parameters like URL and timeout
    API_DOMAIN: 'http://php72.localhost.de',
    API_BASE_URL: 'http://php72.localhost.de/api/index.php/',
    //Api fetch timeout in milliseconds (ms)
    API_TIMEOUT: '30000',
    //Api parameters for graphQl access
    API_GRAPHQL_BASE_URL: 'https://dsapi.staging.ikusolutions.com/api/graphql/',
    //Url to get the hardwaredata as an object from the raspberryPi
    HW_URL: 'http://127.0.0.1/macAddress.js',
    //System runs in [dev/prod] mode
    RUN_SYS: 'dev',
    //AWS S3 access parameters
    S3: {
        BUCKET: "YOUR_DEV_S3_UPLOADS_BUCKET_NAME"
    },
    API_GATEWAY: {
        REGION: "YOUR_DEV_API_GATEWAY_REGION",
        URL: "YOUR_DEV_API_GATEWAY_URL"
    },
    COGNITO: {
        REGION: "YOUR_DEV_COGNITO_REGION",
        USER_POOL_ID: "YOUR_DEV_COGNITO_USER_POOL_ID",
        APP_CLIENT_ID: "YOUR_DEV_COGNITO_APP_CLIENT_ID",
        IDENTITY_POOL_ID: "YOUR_DEV_IDENTITY_POOL_ID"
    }
};

/** 
 * @description Configuration parameters for STAGING state
 * @type {object}
*/
const stag = {
    //Debugmode on/off with [true/false]
    DEBUG: false,
    //Debuglevel only show messages if DEBUG is set true
    // 0 = show only error messages
    // 1 = show additionally warn messages
    // 2 = show additionally info messages
    // 3 = show additionally trace messages
    DEBUG_LEVEL: '0',
    //Debugtext shows on screen
    DEBUG_WARNING: 'DEBUG MODE',
    //Debugclock shows on screen
    // 0 = off
    // 1 = digital clock
    DEBUG_CLOCK: '0',
    //Static clock used
    // 0 = off
    // 1 = on
    DEBUG_STATIC_CLOCK: '0',
    //Set time string e.g. ""
    DEBUG_STATIC_CLOCK_TIME: '',
    //Load debug dummy data instead of fetch api data [true/false]
    DEBUG_DUMMYDATA: false,
    //Interval to check the eventtime to remove obsolet events [minutes]
    CHECK_INTERVAL: '30',
    //Interval to fetch data from api [seconds]
    GET_INTERVAL: '60',
    //Interval to slide the events entries [ms]
    SLIDER_SPEED: '10000',
    //Api parameters like URL and timeout
    API_DOMAIN: 'http://php72.localhost.de',
    API_BASE_URL: 'http://php72.localhost.de/api/index.php/',
    //Api fetch timeout in milliseconds (ms)
    API_TIMEOUT: '30000',
    //Api parameters for graphQl access
    API_GRAPHQL_BASE_URL: 'https://dsapi.staging.ikusolutions.com/api/graphql/',
    //Url to get the hardwaredata as an object from the raspberryPi
    HW_URL: 'http://127.0.0.1:8080/systemInfo/macAddress.js',
    //System runs in [dev/prod] mode
    RUN_SYS: 'prod',
    //AWS S3 access parameters
    S3: {
        BUCKET: "YOUR_PROD_S3_UPLOADS_BUCKET_NAME"
    },
    API_GATEWAY: {
        REGION: "YOUR_PROD_API_GATEWAY_REGION",
        URL: "YOUR_PROD_API_GATEWAY_URL"
    },
    COGNITO: {
        REGION: "YOUR_PROD_COGNITO_REGION",
        USER_POOL_ID: "YOUR_PROD_COGNITO_USER_POOL_ID",
        APP_CLIENT_ID: "YOUR_PROD_COGNITO_APP_CLIENT_ID",
        IDENTITY_POOL_ID: "YOUR_PROD_IDENTITY_POOL_ID"
    }
};

/** 
 * @description Configuration parameters for PRODUCTION state
 * @type {object}
*/
const prod = {
    //Debugmode on/off with [true/false]
    DEBUG: false,
    //Debuglevel only show messages if DEBUG is set true
    // 0 = show only error messages
    // 1 = show additionally warn messages
    // 2 = show additionally info messages
    // 3 = show additionally trace messages
    DEBUG_LEVEL: '0',
    //Debugtext shows on screen
    DEBUG_WARNING: 'DEBUG MODE',
    //Debugclock shows on screen
    // 0 = off
    // 1 = digital clock
    DEBUG_CLOCK: '0',
    //Static clock used
    // 0 = off
    // 1 = on
    DEBUG_STATIC_CLOCK: '0',
    //Set time string e.g. ""
    DEBUG_STATIC_CLOCK_TIME: '',
    //Load debug dummy data instead of fetch api data [true/false]
    DEBUG_DUMMYDATA: false,
    //Interval to check the eventtime to remove obsolet events [minutes]
    CHECK_INTERVAL: '30',
    //Interval to fetch data from api [minutes]
    GET_INTERVAL: '60',
    //Interval to slide the events entries [ms]
    SLIDER_SPEED: '10000',
    //Api parameters like URL and timeout
    API_DOMAIN: 'http://php72.localhost.de',
    API_BASE_URL: 'http://php72.localhost.de/api/index.php/',
    //Api fetch timeout in milliseconds (ms)
    API_TIMEOUT: '30000',
    //Api parameters for graphQl access
    API_GRAPHQL_BASE_URL: 'https://dsapi.staging.ikusolutions.com/api/graphql/',
    //Url to get the hardwaredata as an object from the raspberryPi
    HW_URL: 'http://127.0.0.1:8080/systemInfo/macAddress.js',
    //System runs in [dev/prod] mode
    RUN_SYS: 'prod',
    //AWS S3 access parameters
    S3: {
        BUCKET: "YOUR_PROD_S3_UPLOADS_BUCKET_NAME"
    },
    API_GATEWAY: {
        REGION: "YOUR_PROD_API_GATEWAY_REGION",
        URL: "YOUR_PROD_API_GATEWAY_URL"
    },
    COGNITO: {
        REGION: "YOUR_PROD_COGNITO_REGION",
        USER_POOL_ID: "YOUR_PROD_COGNITO_USER_POOL_ID",
        APP_CLIENT_ID: "YOUR_PROD_COGNITO_APP_CLIENT_ID",
        IDENTITY_POOL_ID: "YOUR_PROD_IDENTITY_POOL_ID"
    }
};

/**  
 * @type {object}
*/  
const config = (process.env.REACT_APP_ENV === 'prod') ? prod : (process.env.REACT_APP_ENV === 'dev') ? dev : stag;

/** 
 * @description Configuration parameters for PRODUCTION state
 * @function showDebugMessage
 * @return {string}
*/
function showDebugMessage() {
    if(config.DEBUG) return config.DEBUG_WARNING;
}

if(config.DEBUG) {
    localStorage.setItem('debug', 'ds-event-app:*');
}

export default {
    showDebugMessage,
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};
